import React, { useState } from "react";

import Layout from "../components/Layout/Layout";
import RainDrops from "../components/RainDrops/RainDrops";
import useInterval from "../hooks/useInterval";

import {
  contentWrapper,
  hero,
  subheadings,
  fadeHeading,
  fadeSpan,
} from "./index.module.scss";

const IndexPage = () => {
  const [fadeClass, setFadeClass] = useState(false);

  useInterval(() => setFadeClass(true), 1500);

  return (
    <Layout>
      <section className={`grid-wrapper ${hero}`}>
        <RainDrops />
        <div className={contentWrapper}>
          <h1 className={fadeClass ? fadeHeading : ""}>Yolanda Bonnell</h1>
          <p className={subheadings}>
            <span className={fadeClass ? fadeSpan : ""}>storyteller,</span>{" "}
            <span className={fadeClass ? fadeSpan : ""}>space holder,</span>{" "}
            <span className={fadeClass ? fadeSpan : ""}>aquarius.</span>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export { Head } from "../components/Head/Head";
