import React from "react";

import {
  container,
  rain,
  waves,
  // drop,
  // splash,
  // particles,
} from "./RainDrops.module.scss";

const RainDrops = () => {
  return (
    <div aria-hidden={true} className={container}>
      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>

      <div className={rain}>
        {/* <div className={drop}></div> */}
        <div className={waves}>
          <div></div>
          <div></div>
        </div>
        {/* <div className={splash}></div> */}
        {/* <div className={particles}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </div>
    </div>
  );
};

export default RainDrops;
